/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {combo} from "./helpers";

function getGroups() {
  return [
    groups.face,
    groups.skin,
    groups.head,
  ];
}

function createCombo(comboParams) {
  const templateParams = {
    canvas_template_name: comboParams.position[1],
    mask_template_name: comboParams.position[2],
    gender: "#0",
    seeds: comboParams.seed,
    prompt: comboParams.prompt,
    negative_prompt: comboParams.negativePrompt,
    strength: comboParams.strength,
    canvas_target: 2,
    mask_target: 2,
    ...comboParams.templateParams,
  };

  const steps = [
    {id: "gender_classifier"},
  ];

  const mainTemplateStep = {id: 8703, templateParams};
  if (steps.length === 1) {
    mainTemplateStep.images = [{src: "@" + comboParams.fileIndex}];
  }

  steps.push(mainTemplateStep);

  let nameSegments = [];
  steps.forEach((step, index) => {
    if (index > 0) {
      nameSegments.push(step.id);
    }
  })

  const name = nameSegments.join("_") + ",seed:" + comboParams.seed + `,str:${comboParams.strength}`;
  const item = combo(steps);
  item.setGroup(comboParams.position[0]);
  item.setExtra(Creative.EXTRA_NAME, name);

  return item;
}

function getCreatives(
  fileIndex,
  seeds,
  prompt,
  negativePrompt,
  strengths
) {
  const results = [];
  const positions = [
    // [groups.face, 8659, 8660],
    // [groups.skin, 8661, 8662],
    [groups.head, 8701, 8702],
  ];

  positions.forEach((position) => {
    seeds.forEach((seed) => {
      strengths.forEach((strength) => {
        const combo = createCombo({
          fileIndex,
          position,
          seed: seed,
          prompt,
          negativePrompt,
          strength,
        });

        combo.setExtra("group_1", position[0]);
        combo.setExtra("group_2", seed);
        combo.setExtra("group_3", strength);

        results.push(combo);
      });
    });
  });

  return results;
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function(fileIndex, seeds, prompt, negativePrompt, strengths) {
    return getCreatives(fileIndex, seeds, prompt, negativePrompt, strengths);
  },
};
